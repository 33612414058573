import React, {useEffect, useRef} from 'react';
import 'flickity/dist/flickity.css';
import normalizeWheel from 'normalize-wheel';
let Flickity = () => {};

if (typeof window !== 'undefined') {
	Flickity = require('flickity-imagesloaded');
}

const SCROLL_FACTOR = 5;
const DELTA_THRESHOLD = -10;

const FlickitySlideshow = ({children, options = {}, sliderRef, ...props}) => {
	const containerRef = useRef();
	let slideshowInstance;

	const initSlideshow = () => {
		const slideshow = new Flickity(containerRef.current, options);

		return slideshow;
	};

	useEffect(() => {
		if (!containerRef.current) return;

		// Destroy before recreating change
		slideshowInstance && slideshowInstance.destroy();

		// Save slideshow ref
		slideshowInstance = initSlideshow();

		const onScroll = event => {
			// Prevent horizontal over scrolling
			if (
				Math.abs(event.wheelDeltaX) >
				Math.abs(event.wheelDeltaY) + DELTA_THRESHOLD
			) {
				event.stopPropagation();
				event.preventDefault();
			}

			const wheelNormalized = normalizeWheel(event);

			slideshowInstance.applyForce(
				wheelNormalized.spinX * -SCROLL_FACTOR,
			);
			slideshowInstance.startAnimation();
			slideshowInstance.dragEnd();
		};

		// Allow scroll only for freescroll
		if (options.freeScroll) {
			slideshowInstance.element.addEventListener('wheel', onScroll);
		}

		// Send slideshow to parent
		if (sliderRef) sliderRef(slideshowInstance);

		// Destroy slideshow on unmount
		return () => {
			slideshowInstance.element.removeEventListener('wheel', onScroll);
			slideshowInstance && slideshowInstance.destroy();
		};
	}, []);

	return (
		<div {...props} ref={containerRef}>
			{children}
		</div>
	);
};

export default FlickitySlideshow;
